import React, { useState, useContext } from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {Box, useTheme} from '@mui/system';
import {Button, Grid, IconButton, InputLabel, Link, Stack, TextField, Typography} from '@mui/material';
import whiteLogo from '../../../assets/white_logo.svg';
import smallLogo from '../../../assets/single_letter_logo.svg'
import useAuth from '../Hooks/useAuth';
import useAnalytics from '../Hooks/useAnalytics';
import { UserContext } from '../Hooks/useUser';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function Login() {
    let navigate = useNavigate();
    const theme = useTheme();
    const { login } = useAuth();
    const { state } = useLocation();
    const analytics = useAnalytics();
    const [searchParams, ] = useSearchParams();

    const [email, setEmail] = useState(searchParams.get('email') ?? '');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isError, setIsError] = useState(false);
    const { setUser } = useContext(UserContext);

    function determineStartingRoute(user) {
        if (user.roles.some(role => role.name === 'Campaign Monitor')) {
            return '/campaignMonitoring/campaigns';
        } else if (user.roles.some(role => role.name === 'Card Management')) {
            return '/cards';
        } else if (user.roles.some(role => role.name === 'Inventory Management')) {
            return '/inventory';
        } else if (user.roles.some(role => role.name === 'User Management')) {
            return '/users';
        }
        return '/cards';
    }

    function handleLogin(event) {
        event.preventDefault();
        setIsError(false);
        login(email, password)
            .then((response) => {
                setUser(response.data.user);
                analytics.identify(response.data.user.id, {
                    'name': response.data.user.name,
                    'email': response.data.user.email
                });
                navigate(state?.path || determineStartingRoute(response.data.user), {replace: true});
            })
            .catch((error) => {
                if (error === 'Account Locked') {
                    navigate('/lockedAccount');
                } else {
                    setIsError(true);
                }
            });
    }

    function handleForgotPassword() {
        navigate('/recovery');
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Grid container sx={{ display: 'flex', height: '100%', width: '100%', backgroundColor: '#F7F9FC' }}>
            <Grid item xs={0} sm={2} md={3} />
            <Grid item xs={12} sm={8} md={6} height='100%' display='grid'>
                <Stack direction='row' sx={{ alignSelf: 'center', boxShadow: 4, borderRadius: '13px' }}>
                    <Stack direction='row' px={4} py={6} spacing={6} sx={{ minWidth: '60%', borderTopLeftRadius: '13px', borderBottomLeftRadius: '13px' }}>
                        <Box>
                            <img src={smallLogo} alt="DOCSI Mini Logo" width='40' height='30' />
                        </Box>
                        <Stack spacing={4} sx={{ justifySelf: 'center', alignSelf: 'center', width: '70%' }}>
                            <Stack spacing={1}>
                                <Typography variant='heavy' sx={{ fontSize: '24px' }}>Welcome to DOCSI</Typography>
                                <Typography variant='normal' sx={{ fontSize: '16px', color: '#00000099' }}>Enter your credentials to access your account</Typography>
                            </Stack>
                            <Box mb={4}>
                                { isError && 
                                    <InputLabel error = {true}  htmlFor='email' sx={{ pb: 1 }}>Invalid username or password</InputLabel>
                                }
                                <TextField fullWidth id='email' variant='outlined' label='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Box>
                            <Box>
                                <TextField 
                                    fullWidth 
                                    variant='outlined' 
                                    label='Password' 
                                    type={showPassword ? 'text' : 'password'}
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                edge='end'
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Box>
                            <Stack spacing={2}>
                                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <Button variant='contained' onClick={handleLogin} sx = {{ color: `${theme.palette.background.white}`, backgroundColor : '#2C2CFF', '&:hover': {bgcolor:'#2C2CFF'}}}>Sign In</Button>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <Button variant='text' sx  = {{color : '#2C2CFF'}} onClick={handleForgotPassword}>Forgot Password?</Button>
                                </Box>
                            </Stack>
                            <Box sx={{font: 'normal normal normal 16px/24px Roboto'}}>Need Help? <Link color = '#2C2CFF' target="_blank"  href = "https://docs.google.com/forms/d/e/1FAIpQLSdZk2yXi-G7yU_dhUurqcd_id2ffgXYXh46m0tNwW2EJJmD-Q/viewform">Reach Out</Link></Box>
                        </Stack>
                    </Stack>
                    <Box sx={{ display: { xs: 'none', lg: 'flex' }, width: '100%', backgroundColor: '#2C2CFF', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: '13px', borderBottomRightRadius: '13px' }}>
                        <img src={whiteLogo} alt="DOCSI Logo" width='75%' style={{ objectFit: 'contain' }} />
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={0} sm={2} md={3} />
        </Grid>
    );
}

export default Login;