import { ArrowRightRounded } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, ListItem, Typography, TextField } from '@mui/material';
import React, { useState } from 'react';


function ProcedureList(props) {
    const [isEditing, setIsEditing] = useState(false);

    function handleSave() {
        props.saveCardChanges();
        setIsEditing(false);
    }

    return (
        <Dialog onClose={props.handleClose} open={props.open} >
            <DialogTitle>
                <>Preference Card Description:</><br/>
                <Typography variant='heavy' fontSize='16px' lineHeight='1.3'><strong>{props.cardData.name}</strong></Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.cardData.procedures.length > 0 &&
                        <>
                            This preference card is designated to be used for the following procedures:
                            {props.cardData.procedures.map((procedure) => {
                                return (
                                    <ListItem mt={1}>
                                        <ArrowRightRounded />
                                        <strong>{procedure.name}</strong>
                                    </ListItem>
                                );
                            })}
                        </>
                    }
                    { isEditing &&
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            sx={{ whiteSpace: 'pre-line' }}
                            value={props.cardData.custom_modal_text}
                            onChange={(e) => props.setCardData({...props.cardData, custom_modal_text: e.target.value})} 
                        />
                    }
                    { props.cardData.custom_modal_text && !isEditing &&
                        <Typography variant='normal' sx={{ fontSize: '14px', marginTop: '8px' }}>
                            <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-line' }}>
                                {props.cardData.custom_modal_text}
                            </pre>
                        </Typography>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                { props.canEdit && !isEditing &&
                    <Button variant='outlined' onClick={() => setIsEditing(true)}>
                        Edit
                    </Button>
                }
                { props.canEdit && isEditing &&
                    <Button variant='outlined' onClick={handleSave}>
                        Save
                    </Button>
                }
                <Button variant='outlined' onClick={props.handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProcedureList;
