import React, { useContext, useEffect, useState } from 'react';
import { Stack, TextField, Autocomplete, Button, Paper, Typography, InputAdornment } from '@mui/material';
import { useGetCardOwnersQuery, useSearchCardsQuery } from '../../api/cardSlice';
import { Add, Search, FilterList } from '@mui/icons-material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Hooks/useUser';
import GenericTable from '../Tables/GenericTable';
import useTableSearch from '../Hooks/useTableSearch';
import { useMediaQuery, useTheme } from '@mui/material';

function CardList() {
    const navigate = useNavigate();
    const { user, hasCardManagement } = useContext(UserContext);
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [selectedPhysician, setSelectedPhysician] = useState(null);
    useEffect(() => {
        const storedFilter = localStorage.getItem('selectedPhysician');
        if (storedFilter) {
            setSelectedPhysician(JSON.parse(storedFilter));
        }
    }, []);
    useEffect(() => {
        if (selectedPhysician) {
            localStorage.setItem(
                'selectedPhysician',
                JSON.stringify(selectedPhysician)
            );
        } else {
            localStorage.removeItem('selectedPhysician');
        }
    }, [selectedPhysician]);

    const {
        searchTerm,
        handleSearchTermChange,
        handleFilterChange,
        page,
        setPage,
        resetSearch,
    } = useTableSearch('cards');

    const {
        data: fetchedCards = {},
        isSuccess,
    } = useSearchCardsQuery({
        hospitalId: user.hospital.id,
        page: page,
        searchTerm: searchTerm,
        filter: selectedPhysician ? selectedPhysician.name : null,
    });

    const {
        data: physicians = [],
    } = useGetCardOwnersQuery(user.hospital.id);

    const sortedPhysicianNames = [...physicians].sort((a, b) =>
        a.last_name.localeCompare(b.last_name)
    );

    const handlePageChange = async (newPage) => {
        setPage(newPage);
        sessionStorage.setItem('cardsPage', newPage);
    };

    const tablePage = useMemo(() => {
        return page - 1;
    }, [page]);

    const totalEntries = useMemo(() => {
        if (fetchedCards.meta) {
            return fetchedCards.meta?.total;
        }

        return 0;
    }, [fetchedCards]);

    function navigateToCreateCard() {
        navigate('/cards/create');
    }

    useEffect(() => {
        resetSearch();
    }, [user.hospital.id]);

    const tableHeaders = [
        'Card ID',
        'Card Name',
        'Physician',
        'Cost',
        ''
    ].concat(isSmScreen ? [] : ['']);

    return (
        <Stack spacing={2} px={{ xs: 2, md: 6 }} pb={2} sx={{ height: '100%' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: '20px', letterSpacing: '0.15px' }}>
                    Preference Cards
                </Typography>
                {hasCardManagement() &&
                    <Button size='small' variant='contained' onClick={navigateToCreateCard} sx={{ display: { xs: 'inline-flex', md: 'none' } }} startIcon={<Add />}>
                        Add New Card
                    </Button>
                }
            </Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' justifyContent={{ xs: 'space-between', md: 'start' }} spacing={2} width={{ xs: '100%', md: '70%', lg: '60%' }}>
                    <Paper elevation={2} sx={{ width: '50%' }}>
                        <TextField
                            value={searchTerm}
                            onChangeCapture={handleSearchTermChange}
                            placeholder="Search Card Templates"
                            size="small"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Paper>
                    <Paper elevation={2} sx={{ width: '50%' }}>
                        <Autocomplete
                            options={sortedPhysicianNames}
                            getOptionLabel={(option) =>
                                `${option.last_name}, ${option.first_name}`
                            }
                            value={selectedPhysician}
                            onChange={(event, newValue) => {
                                setSelectedPhysician(newValue);
                                handleFilterChange(event, newValue);
                            }}
                            size="small"
                            blurOnSelect
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Filter by physician"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FilterList />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={
                                        selectedPhysician
                                            ? `${selectedPhysician.last_name}, ${selectedPhysician.first_name}`
                                            : ''
                                    }
                                    onChange={(event) => {
                                        setSelectedPhysician(null);
                                        handleFilterChange(event, null);
                                    }}
                                />
                            )}
                        />
                    </Paper>
                </Stack>
                {hasCardManagement() &&
                    <Button variant='contained' size='small' sx={{ display: { xs: 'none', md: 'inline-flex' } }} onClick={() => navigate('/cards/create')} startIcon={<Add />}>
                        Add New Card
                    </Button>
                }
            </Stack>
            <GenericTable
                data={fetchedCards.data}
                dataType="card"
                isDataLoaded={isSuccess}
                headers={tableHeaders}
                page={tablePage}
                setPage={handlePageChange}
                total={totalEntries}
            />
        </Stack>
    );
}

export default CardList;
