import { Typography } from '@mui/material';
import React from 'react';

function OptimizationContextBenefit(props) {
    if (props.recommendation.source === 'time_series') {
        if (props.recommendation.type === 'removal') {
            return (
                <Typography variant='caption' sx={{ fontSize: '12px' }}>
                    <em>Removing this item saves staff time and speeds up turnover by reducing inventory picking, setup, and restocking.</em>
                </Typography>
            )
        } else if (props.recommendation.type === 'update' && props.recommendation.open_quantity < props.item.open_quantity) {
            return (
                <Typography variant='caption' sx={{ fontSize: '12px' }}>
                    <em>This item is sometimes being opened but not used, causing waste. Eliminate waste by reducing the open quantity.</em>
                </Typography>
            )
        } else if (props.recommendation.type=== 'update' && (props.recommendation.open_quantity + props.recommendation.hold_quantity > props.item.open_quantity + props.item.hold_quantity)) {
            return (
                <Typography variant='caption' sx={{ fontSize: '12px' }}>
                    <em>Increasing this item quantity ensures it's available when needed, preventing mid-case delays.</em>
                </Typography>
            )
        } else if (props.recommendation.type === 'update') {
            return (
                <Typography variant='caption' sx={{ fontSize: '12px' }}>
                    <em>Decreasing the item quantity boosts OR efficiency by reducing the amount of inventory picked and restocked for each case.</em>
                </Typography>
            )
        }  else if (props.recommendation.type === 'addition') {
            return (
                <Typography variant='caption' sx={{ fontSize: '12px' }}>
                    <em>Adding this item ensures it's available when needed, preventing mid-case delays.</em>
                </Typography>
            )
        }
    } else if (props.recommendation.source === 'card_comparison') {
        if (props.recommendation.type === 'removal') {
            return (
                <Typography variant='caption' sx={{ fontSize: '12px' }}>
                    <em>Removing this item helps reduce clinical variation and reduces the number of supplies your team needs to manage.</em>
                </Typography>
            )
        } else if (props.recommendation.type === 'update' && props.recommendation.open_quantity < props.item.open_quantity) {
            return (
                <Typography variant='caption' sx={{ fontSize: '12px' }}>
                    <em>This item is sometimes being opened but not used, causing waste. Eliminate waste by reducing the open quantity.</em>
                </Typography>
            )
        } else if (props.recommendation.type === 'update' && (props.recommendation.open_quantity + props.recommendation.hold_quantity < props.item.open_quantity + props.item.hold_quantity)) {
            return (
                <Typography variant='caption' sx={{ fontSize: '12px' }}>
                    <em>Decreasing the item quantity boosts OR efficiency by reducing the amount of inventory picked and restocked for each case.</em>
                </Typography>
            )
        } else if (props.recommendation.type === 'update') {
            return (
                <Typography variant='caption' sx={{ fontSize: '12px' }}>
                    <em>Increasing the item quantity ensures it's available when needed, preventing mid-case delays.</em>
                </Typography>
            )
        }
    }
    return <></>;
}

export default OptimizationContextBenefit;
