import { Delete, ErrorOutline, Print } from '@mui/icons-material';
import { IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import React, { useContext, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { useDeleteCardMutation } from '../../../api/cardSlice';
import DeleteDataConfirmation from '../../Card/DeleteDataConfirmation';
import { UserContext } from '../../Hooks/useUser';
import axios from 'axios';

function CardRow(props) {
    const navigate = useNavigate();
    const { hasCardManagement } = useContext(UserContext);
    const [isShowingDeleteConfirmation, setIsShowingDeleteConfirmation] = useState(false);
    const [deleteCard] = useDeleteCardMutation();

    const deleteData = async() =>{
        await deleteCard({
            card: props.card,
        });
    }

    function goToCard(card) {
        navigate(`/cards/${card.id}`);
    }

    function handleClickPrint(event) {
        event.stopPropagation();
        return new Promise((res) => {
            axios({
                url: `/api/cards/${props.card.id}/printedCard`,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                var pdf = window.open(url, '_blank');
                pdf.print();
                res();
            });
        });
    }

    function handleDeleteClick(event) {
        event.stopPropagation();
        setIsShowingDeleteConfirmation(true);
    }

    return (
        <TableRow onClick={() => goToCard(props.card)} hover sx={{ cursor: 'pointer' }}>
            <TableCell>
                {props.card.external_card_id}
            </TableCell>
            <TableCell>
                <Stack direction='row' spacing={1} alignItems='center'>
                    {props.card.name}
                    { props.card.in_warning && 
                        <Tooltip disableFocusListener title='This card contains items that are inactive in your inventory.'>
                            <ErrorOutline color='warning' style={{ opacity: 0.8 }} />
                        </Tooltip>
                    }
                </Stack>
            </TableCell>
            <TableCell>
                {props.card.owner.name}
            </TableCell>
            <TableCell>
                <NumericFormat
                    value={props.card.open_cost / 100}
                    thousandSeparator={true}
                    displayType='text'
                    prefix='$'
                    decimalScale={0}
                /> Open / <NumericFormat
                    value={props.card.total_cost / 100}
                    thousandSeparator={true}
                    displayType='text'
                    prefix='$'
                    decimalScale={0}
                /> Total
            </TableCell>
            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                <IconButton onClick={(e) => handleClickPrint(e)}> 
                    <Print />
                </IconButton>
            </TableCell>
            { hasCardManagement() && 
                <TableCell>
                    <DeleteDataConfirmation open={isShowingDeleteConfirmation} deleteData = {deleteData} dataType = {'Card'} setOpen={setIsShowingDeleteConfirmation} id = {props.card.id}/>
                    <IconButton onClick={handleDeleteClick}>
                        <Delete />
                    </IconButton>
                </TableCell>
            }
        </TableRow>
    )
}

export default CardRow;
