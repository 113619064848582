import { Stack, Button, Skeleton, Typography, AppBar, IconButton } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React, {useEffect, useMemo, useState} from 'react';
import CountUp from 'react-countup';
import { ArrowBackIos, ChevronRightRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../Hooks/useAnalytics';
import ProgressBar from './ProgressBar';
import EfficiencyExplainer from './EfficiencyExplainer';
import ProcedureList from './ProcedureList';
import { NumericFormat } from 'react-number-format';
import AverageCostChip from '../CardList/AverageCostChip';

function CardTitle(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const [isShowingEfficiencyExplainer, setIsShowingEfficiencyExplainer] = useState(false);
    const [isShowingProcedureList, setIsShowingProcedureList] = useState(false);

    useEffect(() => {
        if (props.draft) {
            props.updateCardSavings(props.draft.total_savings / 100);
        } 
    }, [props.draft?.total_savings])

    const savingsColor = useMemo(() => {
        if (Math.round(props.currentSavings)>=0) {
            return `${theme.palette.blue.main}`;
        } else if (Math.round(props.currentSavings)<0) {
            return 'Red';
        }
    }, [props.currentSavings]);

    const allOptimizationRecsAccepted = useMemo(() => {
        if (!props.draft) {
            return false;
        }

        var hasAcceptedAllRecs = true;
        Object.keys(props.draft.recommendations).forEach((category) => {
            props.draft.recommendations[category].forEach((rec) => {
                if (rec.type === 'addition' || rec.type === 'removal' || rec.type === 'update') {
                    if (rec.status === 'rejected' || rec.status === 'open') {
                        hasAcceptedAllRecs = false;
                        return;
                    } 
                }
            });
        });

        return hasAcceptedAllRecs;
    }, [props.draft?.recommendations]);

    function navigateToAllCardsView() {
        analytics.track('Button Clicked', {
            label: 'Dashboard',
            sector: 'Physician Dashboard',
            location: 'Item Details'
        });
        sessionStorage.removeItem('cardReviewScrollPosition');
        navigate(`/physicianDashboard/headline/${props.draft?.campaign_id}`)
    }

    return (
        <AppBar position='sticky' sx={{ color: 'black', backgroundColor: '#FFFFFF' }}>
            <Stack pt={2} px={2} sx={{ boxShadow: 2, display: 'grid' }}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Stack sx={{ backgroundColor: 'white', width: '100%' }}>  
                        <Box alignSelf='start' sx={{ display: 'flex' }}>
                            { props.draft &&
                            <Button 
                                variant='text'
                                startIcon={<ArrowBackIos />} 
                                onClick={navigateToAllCardsView}
                                style={{ color: `${theme.palette.hold.main}`, paddingTop: '0px', paddingBottom: '0px'}}
                            >Dashboard</Button>
                            }
                        </Box>
                        <Stack direction='row' spacing={0.5} my={1} alignItems='center' maxWidth='95%'>
                            <Box sx={{ 
                                fontSize: '14px', 
                                color: 'black',
                                opacity : '87%', 
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'initial',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box'
                            }}>
                                { props.isLoading ? <Skeleton variant='text' width={175} /> : <Typography variant='heavy'>{props.draft?.card.name}</Typography> }
                            </Box>
                            { (props.draft?.card.procedures.length > 0 || props.draft?.card.custom_modal_text) &&
                                <>
                                    <IconButton size='small' onClick={() => setIsShowingProcedureList(true)} sx={{
                                        borderRadius: '4px',
                                        border: '1px solid #0000001F',
                                        padding: '4px'
                                    }}>
                                        <ChevronRightRounded />
                                    </IconButton>
                                    <ProcedureList 
                                        open={isShowingProcedureList} 
                                        handleClose={() => setIsShowingProcedureList(false)} 
                                        cardData={props.draft?.card} 
                                        canEdit={false}
                                    />
                                </>
                            }
                        </Stack>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            { props.draft?.card?.yearly_stats?.usage_count && 
                                <Box py={0.5} px={1} sx={{ color: '#00000099', backgroundColor: '#0000000F', borderRadius: '4px', fontSize: '10px' }}>
                                    <Typography variant='heavy'>{props.draft.card.yearly_stats.usage_count} Cases /Year</Typography>
                                </Box>
                            }
                            { props.draft && 
                                <Box py={0.5} px={1} sx={{ color: '#00000099', backgroundColor: '#0000000F', borderRadius: '4px', fontSize: '10px' }}>
                                    <Typography variant='heavy'>
                                        <NumericFormat
                                            value={props.draft.total_card_carry / 100}
                                            thousandSeparator={true}
                                            displayType='text'
                                            prefix='$'
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                        />
                                        {' card cost'}</Typography>
                                </Box>
                            }
                            <AverageCostChip draft={props.draft} />
                        </Stack>            
                    </Stack>
                    <Stack spacing={0.5} justifyContent='center'>
                        <Box alignSelf='center' textAlign='center' sx={{ fontSize: '10px', color: '#00000099' }}>
                            <Typography variant='normal'>Estimated Cost Reduction /yr</Typography>
                        </Box>
                        <Box py={0.5} px={1} alignSelf='center' sx={{ color: savingsColor, font: 'normal normal 18px Roboto', border: '1px solid #0000001F', borderRadius: '4px', maxWidth: 'fit-content' }}>
                            { props.isLoading 
                                ? <Skeleton variant='rectangular' width={100} height={30} />
                                : <CountUp 
                                    start={props.oldSavings}
                                    end={props.currentSavings}
                                    duration={0.4}
                                    separator=','
                                    decimals={0} 
                                    prefix='$'
                                />
                            }
                        </Box>  
                    </Stack>
                </Stack>
                <Box pb={2}>
                    { props.draft &&
                        <ProgressBar 
                            efficiency={props.draft?.efficiency} 
                            label='Card Cost Efficiency'
                        />
                    }
                </Box>
                { props.draft && allOptimizationRecsAccepted && props.draft.efficiency * 100 !== 100 &&
                    <Button variant='text' onClick={() => setIsShowingEfficiencyExplainer(true)} style={{ color: `${theme.palette.subtext.main}`, justifySelf: 'end', marginTop: '-8px' }}>Why is my efficiency not 100%?</Button>
                }
                <EfficiencyExplainer open={isShowingEfficiencyExplainer} handleClose={() => setIsShowingEfficiencyExplainer(false)} />
            </Stack>
        </AppBar>        
    );
}

export default CardTitle;
