import { TrendingDownRounded, TrendingUpRounded, KeyboardDoubleArrowUpRounded, KeyboardDoubleArrowDownRounded, LegendToggleRounded, GroupsRounded, VerticalAlignBottomRounded, VerticalAlignTopRounded, FmdBadOutlined, FlagOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';

function OptimizationContextBullet(props) {
    if (props.type === 'low_utilization') {
        return (
            <OptimizationBullet
                icon={<TrendingDownRounded fontSize='small' />}
                title='Rarely Used'
                text={<span>You used this item in <strong>{Math.round(props.data*100)}%</strong> of your cases</span>}
            />
        );
    } else if (props.type === 'high_utilization') {
        return (
            <OptimizationBullet
                icon={<TrendingUpRounded fontSize='small' />}
                title='Frequently Used'
                text={<span>You used this item in <strong>{Math.round(props.data*100)}%</strong> of your cases</span>}
            />
        );
    } else if (props.type === 'low_procedure_prevalence') {
        return (
            <OptimizationBullet
                icon={<GroupsRounded fontSize='small' />}
                title='Procedure Prevalence'
                text={<span>This item only appears on <strong>{Math.round(props.data*100)}%</strong> of your peers' cards for this procedure</span>}
            />
        );
    } else if (props.type === 'high_average_usage') {
        return (
            <OptimizationBullet
                icon={<VerticalAlignTopRounded fontSize='small'/>}
                title='My Usage'
                text={<span>On average, you used this item <strong>{props.data.usage.toFixed(1)}</strong> times per case, but your card only has <strong>{props.data.quantity}</strong> on it</span>}
            />
        );
    } else if (props.type === 'common_peer_usage') {
        return (
            <OptimizationBullet
                icon={<GroupsRounded fontSize='small'/>}
                title='Procedure Prevalence'
                text={<span>This item appears on <strong>{Math.round(props.data * 100)}%</strong> of your peers' cards for this procedure</span>}
            />
        );
    } else if (props.type === 'insufficient_card_quantity_ts') {
        return (
            <OptimizationBullet
                icon={<KeyboardDoubleArrowUpRounded fontSize='small'/>}
                title='Insufficient Card Quantity'
                text='Recent usage data suggests you use this item more than your current card quantity indicates'
            />
        );
    } else if (props.type === 'insufficient_card_quantity_cc') {
        return (
            <OptimizationBullet
                icon={<KeyboardDoubleArrowUpRounded fontSize='small'/>}
                title='Insufficient Card Quantity'
                text={<span>At least 25% of cases use <strong>{props.data.value}</strong> of this item but your card currently has <strong>{props.data.old_quantity}</strong></span>}
            />
        )
    } else if (props.type === 'elevated_category_spend') {
        return (
            <OptimizationBullet
                icon={<LegendToggleRounded fontSize='small'/>}
                title='High Category Cost'
                text={<span>Your '{props.data.category}' items are <strong>{Math.round(props.data.value * 100)}%</strong> more costly than your peers who perform this procedure</span>}
            />
        )
    } else if (props.type === 'excess_card_quantity_ts') {
        return (
            <OptimizationBullet
                icon={<KeyboardDoubleArrowDownRounded fontSize='small'/>}
                title='Excess Card Quantity'
                text='Recent usage data suggests you use this item less than your current card quantity indicates'
            />
        )
    } else if (props.type === 'excess_card_quantity_cc') {
        return (
            <OptimizationBullet
                icon={<KeyboardDoubleArrowDownRounded fontSize='small'/>}
                title='Excess Card Quantity'
                text={<span>Records show that a typical procedure of this type uses <strong>{props.data.value}</strong> of this item but your card currently has <strong>{props.data.old_quantity}</strong></span>}
            />
        )
    } else if (props.type === 'infrequent_peer_usage') {
        return (
            <OptimizationBullet
                icon={<GroupsRounded fontSize='small'/>}
                title='Infrequent Peer Usage'
                text='Fewer than 25% of procedures of this type at your site use this item'
            />
        )
    } else if (props.type === 'unique_item') {
        return (
            <OptimizationBullet
                icon={<FmdBadOutlined fontSize='small'/>}
                title='Unique Item'
                text='You are the only physician who uses this item for this procedure'
            />
        )
    } else if (props.type === 'outlier_item') {
        return (
            <OptimizationBullet
                icon={<FlagOutlined fontSize='small'/>}
                title='Outlier Item'
                text={<span>This item appears on only <strong>{Math.round(props.data * 100)}%</strong> of cards of this procedure type</span>}
            />
        )
    } else if (props.type === 'low_typical_usage_ts') {
        return (
            <OptimizationBullet
                icon={<VerticalAlignBottomRounded fontSize='small'/>}
                title='Low Typical Usage'
                text={<span><strong>80%</strong> of your cases use <strong>{props.data.value}</strong> or fewer of this item. Adjust your quantity to <strong>{props.data.new_quantity}</strong> to reflect your typical usage.</span>}
            />
        )
    } else if (props.type === 'current_open_usage_percentage') {
        return (
            <OptimizationBullet
                icon={<VerticalAlignBottomRounded fontSize='small'/>}
                title='Typical Usage'
                text={<span><strong>{Math.round(props.data.value * 100)}%</strong> of your cases use fewer than <strong>{props.data.current_open}</strong></span>}
            />
        )
    } else if (props.type === 'low_typical_usage_cc') {
        return (
            <OptimizationBullet
                icon={<VerticalAlignBottomRounded fontSize='small'/>}
                title='Low Typical Usage'
                text={<span><strong>75%</strong> of cases use <strong>{props.data.value}</strong> or fewer of this item. Adjust your quantity to <strong>{props.data.new_quantity}</strong> to reflect typical usage.</span>}
            />
        )
    }
    return <></>;
}

function OptimizationBullet(props) {
    return (
        <Stack direction='row' spacing={0.5} sx={{ fontSize: '12px' }} alignItems='center'>
            {props.icon}
            <Typography variant='normal'>
                <strong>{props.title}</strong> - {props.text}
            </Typography>
        </Stack>
    )
}

export default OptimizationContextBullet;
