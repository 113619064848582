import { Stack } from '@mui/material';
import React from 'react';
import ActiveCardEntry from '../../CardList/ActiveCardEntry';
import SubmittedCardEntry from '../../CardList/SubmittedCardEntry';

function HeadlineCardList(props) {
    return (
        <Stack pb={5} spacing={2}>
            { props.drafts.map((draft) => (
                (draft.status === 'submitted' || draft.status === 'applied') ? 
                    <SubmittedCardEntry key={draft.id} physician={props.currentUser} draft={draft} /> :
                    <ActiveCardEntry key={draft.id} physician={props.currentUser} draft={draft} campaignHasOptimizations={props.campaignHasOptimizations} />
            ))}
        </Stack>
    );
}

export default HeadlineCardList;
